import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import { trackEvent } from '../utils/segmentTrack'
import Text from '../components/common/Text'
import Button from '../components/common/Button'
import BackButton from '../components/common/BackButton'
import Space from '../components/common/Space'
import { calendlyAPIKey } from '../../config/keys'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  margin-top: 80px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  min-height: 40vh;
  height: 100%;
  flex-direction: column;
  padding-bottom: 90px;
  justify-content: center;
`

const LandingWrapper = styled.div`
  padding: 48px 80px;
  background-color: ${p => p.theme.color.offWhite};
  margin-top: 32px;
  border-radius: 6px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 48px 16px;
  }
`

const Header = styled.div`
  padding: 0px 80px;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    padding: 0px 0px;
    padding-bottom: 48px;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  p {
    max-width: 700px;

    @media (max-width: 800px) {
      margin-bottom: 32px;
    }
  }
`

const TimeToChat = () => {
  const [location, setLocation] = useState(null)

  let calendlyID
  if (location === 'national') {
    calendlyID = 'aesales/inbound'
  }
  if (location === 'international') {
    calendlyID = 'international-enterprise-sales/international-schedule-a-meeting-with-audioeye'
  }

  const url = `https://calendly.com/${calendlyID}`

  if (typeof window !== 'undefined') {
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)
  }

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  const fetchInvitee = async inviteeUri => {
    try {
      const inviteeResponse = await fetch(inviteeUri, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${calendlyAPIKey}`,
        },
      })
      return inviteeResponse
    } catch (err) {
      trackEvent(`API Exception`, {
        label: `Calendly tracking general error`,
        full_exception: err,
      })
      return false
    }
  }

  useEffect(() => {
    window.addEventListener('message', async e => {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          const rawInviteeData = await fetchInvitee(e.data.payload.invitee.uri)
          if (!rawInviteeData.ok) {
            trackEvent(`API Exception`, {
              label: `Calendly tracking general error`,
              full_exception: rawInviteeData,
            })
          }
          const inviteeData = await rawInviteeData.json()
          const companyName = inviteeData.resource.questions_and_answers.find(obj => obj.question === 'Company')
          trackEvent(`Calendly Meeting Scheduled`, {
            name: inviteeData.resource.name || '',
            email: inviteeData.resource.email || '',
            company_name: companyName.answer || '',
            calendly_url: calendlyID || '',
          })
        }
      }
    })
  }, [])

  return (
    <Layout showHeader showCTA>
      <Wrapper>
        <Header>
          <Text heading3 role="heading" aria-level="1" center>
            Find some time with our team of accessibility experts to understand your path to compliance
          </Text>
        </Header>
        <ContentWrapper>
          {!location && (
            <LandingWrapper>
              <Text heading4 role="heading" aria-level="2" center>
                Where are you located?
              </Text>
              <ButtonWrapper>
                <Button text="United States" onClick={() => setLocation('national')} />
                <Space height={0} tabletHeight={16} width={32} tabletWidth={0} />
                <Button text="International" onClick={() => setLocation('international')} />
              </ButtonWrapper>
            </LandingWrapper>
          )}
          <FormWrapper>
            {location && (
              <>
                <BackButton onClick={() => setLocation(null)} />
                <div className="calendly-inline-widget" data-url={url} style={{ width: '100%', height: 750 }} />
              </>
            )}
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  )
}

export default TimeToChat
